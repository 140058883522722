import React from 'react'
import Grid from '@material-ui/core/Grid'
// import Alert from '../Alert'
import Card from '../Card'
import { cards } from '../const'

export default () => <Grid container direction="row" style={{ marginTop: '40px', marginBottom: '70px' }}>
    {/* <Alert /> */}
    <Grid item md={2} />
    <Grid item md={8} container direction="row" justify="center" alignItems="stretch" >
        {cards.map((item, index) => <Grid item xs={12} md={6} sm={6} key={item.id + index} style={{ display: 'flex' }}>
            <Card {...item} />
        </Grid>)}
    </Grid>
    <Grid item md={2} />
</Grid >