import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import ToolBar from '@material-ui/core/ToolBar'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

export default ({
    classes
}) =>
    <AppBar position="fixed" className={classes.appBar} elevation={0}>
        <ToolBar>
           <Typography>Copyright © <Link href="http://www.koine-servizi.it/" color="textPrimary" target="_blank">Koinè Servizi Srl</Link> 2021</Typography>
        </ToolBar>
    </AppBar>