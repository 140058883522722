import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import Main from '../Main'

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#db5858',
            main: '#d32f2f',
            dark: '#932020'
        },
        secondary: {
            light: '#d4dd63',
            main: '#cad53d',
            dark: '#8d952a'
        }
    }
})

export default (props) =>
    <ThemeProvider theme={theme}>
        <HelmetProvider>
            <Main {...props} />
        </HelmetProvider>
    </ThemeProvider>
