import React from 'react'
import { Helmet } from 'react-helmet-async'
import CssBaseline from '@material-ui/core/CssBaseline'
import LayoutToolbar from '../LayoutToolbar'
import LayoutMiddle from '../LayoutMiddle'
import LayoutFooter from '../LayoutFooter'
const GOOGLE_APIS_FONTS_ROBOTO = 'https://fonts.googleapis.com/css?family=Roboto:300,400,500'

export default ({
    classes,
    windowTitle = "MySkygate"
}) =>
    <React.Fragment>
        <CssBaseline />
        <Helmet>
            <html lang="it-IT" />
            <link rel="stylesheet" href={GOOGLE_APIS_FONTS_ROBOTO} />
            <title>{windowTitle}</title>
        </Helmet>
        <LayoutToolbar />
        <LayoutMiddle />
        <LayoutFooter />
    </React.Fragment >
