import LogoPadCad from './img/padcad_logo.jpg'
import LogoSKIM from './img/skim_logo.jpg'
import LogoDMT2 from './img/dmt2_logo.jpg'
import LogoCvPrevital from './img/cv_prevital_logo.jpg'
import LogoImprove from './img/IMPROVE.png'
import docDmt2 from './docs/jcm-13-01127-v2.pdf'

export const cards = [
    
    {
        id: 'skim',
        title: 'SKIM',
        img: LogoSKIM,
        description: 'Selezione di persone a basso rischio cardiovascolare: sviluppo di un algoritmo di pre-screening economico utilizzando solo misure non di laboratorio.',
        href: 'https://skygate.koine-servizi.it/SKIM/'
    },
    {
        id: 'cvprevital',
        title: 'CV PREVITAL',
        img: LogoCvPrevital,
        description: 'Strategie di prevenzione primaria cardiovascolare nella popolazione italiana.',
        href: 'https://skygate.koine-servizi.it/cv-prevital/',
        // disabled: true
    },
    {
        id: 'padcad',
        title: 'Pad & Cad',
        img: LogoPadCad,
        description: 'L\'arteriopatia periferica degli arti inferiori nei pazienti con patologia coronarica stabile in Medicina Generale.',
        href: 'https://skygate.koine-servizi.it/PAD_CAD/',
        concluso: true
    },
    {
        id: 'dmt2',
        title: 'DMT2 risk',
        img: LogoDMT2,
        description: 'Requisiti di base e studi preliminari per un programma di prevenzione del diabete di tipo 2 in Italia.',
        href: 'https://skygate.koine-servizi.it/dmt2/',
        concluso: true,
        doc: docDmt2,
        docLabel: 'Leggi l’articolo pubblicato  sul “Journal of Clinical Medicine”'
    },
    
    /*
    {
        id: 'improve',
        title: 'IMPROVE-CVP',
        img: LogoImprove,
        description: 'IMPROVE-CVP sarà presto disponibile.',
        href: '#', // 'https://skygate.koine-servizi.it/improve/'
        disabled: true
    }
    */
]
