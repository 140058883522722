import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import ToolBar from '@material-ui/core/ToolBar'
import MainLogo from '../img/skygate_logo.png'

export default ({
    classes
}) =>
    <AppBar position="fixed" color="transparent" className={classes.appBar} elevation={0}>
        <ToolBar style={{display: 'contents'}}>
            <img src={MainLogo} alt="Logo" className={classes.mainLogo} />
        </ToolBar>
    </AppBar>