import React from 'react'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

export default ({
    classes,
    title,
    img,
    href,
    description,
    concluso,
    disabled,
    doc,
    docLabel
}) =>
    <Card className={classes.cardContainer} elevation={1}>
        <CardActionArea href={href} disabled={disabled || !href || concluso} style={{ flex: 1 }}>
            <CardContent style={{ textAlign: 'center', paddingBottom: 8 }}>
                <img src={img} alt={title} className={classes.media} />
                <Typography component="p" align="left">
                    {description}
                </Typography>
                {concluso && <>
                    <Typography variant='h5' align="left" style={{ marginTop: '20px' }}>
                        <span style={{ color: 'green', marginRight: '10px' }}><b>&#10003;</b></span>{'Concluso'}
                    </Typography>

                </>}
            </CardContent>
        </CardActionArea>
        <CardActions style={{ justifyContent: doc ? 'flex-start' : 'flex-end' }}>
            {!concluso && <Button size="small" variant="contained" color="primary" href={href} disableElevation disabled={disabled || !href}>
                {'Entra'}
            </Button>}
            {doc &&
                <Typography component='a' tartget='_blank' href={doc} style={{ marginLeft: '8px' }}>
                    {docLabel || 'Leggi l\'articolo'}
                </Typography>
            }
        </CardActions>
    </Card>