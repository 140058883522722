import BG from '../img/bg2.jpg'

export default (theme) => ({
    mainLogo: {
        minWidth: '35%',
        maxWidth: '100px',
        padding: theme.spacing(3),
    },
    appBar: {
        // alignItems: 'center',
        position: 'inherit',
        backgroundImage: `url(${BG})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        backgroundPositionX: 'right',
        backgroundPositionY: 'center'
    }
})
