export default () => ({
    cardContainer: {
        margin: '1rem',
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    media: {
        // height: 120,
        maxWidth: '70%'
    }
})
